export class AppSettings {

	//public static API_ENDPOINT = 'http://aalamfolklore.com/aalamproxy/';
	//public static API_ENDPOINT_AUTH = 'http://aalamfolklore.com/aalamproxy/auth/';

	
	 public static API_ENDPOINT = 'http://localhost:8080/trivent/';


	// public static API_ENDPOINT = 'http://192.168.2.27:8080/aalamproxy/';
	//public static API_ENDPOINT_AUTH = 'http://192.168.2.27:8080/aalamproxy/auth/';

	
	

	


	
	//public static API_ENDPOINT = 'http://appserver-live-3tier.iajrkaw4g9.ap-south-1.elasticbeanstalk.com/';
//	public static API_ENDPOINT_AUTH = 'http://appserver-live-3tier.iajrkaw4g9.ap-south-1.elasticbeanstalk.com/auth/';

}