import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		// add authorization header with jwt token if available
		let currentUser = localStorage.getItem('accessToken');
		let currentUserAWS = localStorage.getItem('AWS');
		
		
			request = request.clone({
				setHeaders: {
					'Access-Control-Allow-Origin': '*',
					'Content-Type': 'application/json',
					'Access-Control-Allow-Methods': 'GET, POST, DELETE, PUT',
					'Access-Control-Allow-Headers': 'Content-Type, Accept, X-Requested-With, remember-me, Access-Control-Allow-Origin, Access-Control-Allow-Methods',
					'x-api-key':'EwgToVr3hQ6hrx7MOmKT13iTadNwLmrw2XbrWZpR'
					//'Authorization': ``
				}
			});
		

		

        return next.handle(request);
    }
}