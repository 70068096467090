import { Component, ViewChildren, Input, ViewChild, OnInit, OnChanges, SimpleChanges, SimpleChange, ViewEncapsulation, AfterViewInit, TemplateRef, QueryList, ViewContainerRef,ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CaseService } from './../../../../../core/services/app/case.service';
import { UploadFileService } from '../../../../../core/services/app/upload-file.service';
import { IconforS3downloadComponent } from '../iconfor-s3download/iconfor-s3download.component';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { modelGroupProvider } from '@angular/forms/src/directives/ng_model_group';
import { Toastr } from '../../../../../config/Toastr';

declare let mApp: any;

@Component({
    selector: "completed-file-list",
    templateUrl: "./completed-file-list.component.html",
    encapsulation: ViewEncapsulation.None,
    styles: [`
        .clsFontWeight{
            font-weight:100 !important;
        }
    `]
})

export class CompletedFileListComponent implements OnInit {
	@Input() caseId: String;
	caseresultfiles: any;
	@Input() nonacoountCf: String;
	model: any = {};
	completedList: any = {};
	tableList: any = {};
	//caseId: any;
	selectedSlaves: { [key: number]: boolean; } = {};
	total: any = 0;
	page: any = 1;
	limit: any = 10;
	sortOrder = "decending";
	sortByField = "createdDate";
	modelTemp: any;
	globalFiles: any;
	globalCount: any;
	isDownload: any;
	public modalColumnRefSec: any;
	public modalColumnRef: any;
	
    constructor(
        private ngxToastrService: ToastrService,
		private activatedRoute: ActivatedRoute,
		private caseService : CaseService, 
		private router: Router,
		private uploadService: UploadFileService,
		private _ref: ChangeDetectorRef,
		private modalService: NgbModal,
		private _toastr: ToastrService,
    ) { }

	ngOnInit() {					
		this.screenFilterVO();		
		this.caseresultfiles = "case_result_file";
	}
	screenFilterVO() {
		this.caseService.caseresultfilescreenFilterVO().subscribe(
			data1 => {
				let returnData = data1;				
				if (returnData["isData"]) {				
					this.modelTemp = returnData["data"];
					this.getAllListById(this.modelTemp);
				}
				this._ref.detectChanges();
			},
			error => {
				console.log("error", error);
			});
	}

	

	getAllListById(modelTemp) { 
		this.model.caseEncryptId = this.caseId;
		// console.log("caseId", this.caseId);
		this.model.screenListFilterVO = this.modelTemp;
	
		let projectObj = this.model;
		this.caseService.getCompletedCaseListByEncId(projectObj).subscribe(
			data => { 
				let returnData = data;
				if (returnData["isData"]) { 
					// console.log("ereturnData", returnData);
					this.total = data["paginationVO"].totalRecords;
					// console.log("this.total", this.total);
					// console.log("this.total", this.page);
					// console.log("this.total", this.limit);

					this.completedList = returnData["dataList"][0];
					let headerObj = this.completedList.filter(a => (a.header));
					// console.log("this.completedList", this.completedList);
					let detailObj = this.completedList.filter(a => (!a.header));
					//this.total = data["paginationVO"].totalRecords;
					this.tableList["header"] = headerObj;
					this.tableList["detail"] = detailObj;
					if(this.tableList["detail"].length>0){
				    	this.globalFiles = this.tableList["detail"][0].valueMap.totalCaseFiles;
						this.globalCount = this.tableList["detail"][0].valueMap.caseFilePageCount;						
						this.model.isDown = this.tableList["detail"][0].valueMap.isCustomerDownloaded;
					}					
					this._ref.detectChanges();
				}
			},
			error => {
				console.log("error", error);
			});
	}
   
	onSlaveCheckedChange(i: number, $event) {
		this.selectedSlaves[i] = $event;		
	}

	onMasterCheckedChange($event) {       
        for (var i = 0; i < this.tableList["detail"].length; i++) {
            this.selectedSlaves[i] = $event.target.checked;
        }
    }

	multipleDownload() {
		var toastr = new Toastr();		
		this.appLoadingOverlay("#file-downloading_CR", "File Downloading...", "block");
		let objKeys = Object.keys(this.selectedSlaves);
		let trueObj = objKeys.filter(a => (this.selectedSlaves[a]));
		let fileObj = [];
		trueObj.forEach(b => {
			fileObj.push(this.tableList["detail"][b]);
		})
		if (fileObj.length == 0) {
			this._toastr.error('Please Select File(s)', 'Portal', toastr);
		}
		for (var i = 0; i < fileObj.length; i++){
			let caseFileName = fileObj[i].valueMap.caseFileName;
			let caseid = fileObj[i].valueMap.caseId;
			let repFileLocation = fileObj[i].valueMap.FileLocation;
			let fileType = "case_result_file";
			this.uploadService.downloadFilesFromS3(repFileLocation,caseFileName,fileType,caseid);
		}
		this.appLoadingOverlay("#file-downloading_CR", "", "unblock");
	}
	//pagination functions
	goToPage(n: number): void {
		this.page = n;
		this.getAllRolesPagger({ page: this.page, limit: this.limit }, this.sortOrder, this.sortByField);
	}
	onNext(): void {
		this.page++;
		this.getAllRolesPagger({ page: this.page, limit: this.limit }, this.sortOrder, this.sortByField);
	}
	onPrev(): void {
		this.page--;
		this.getAllRolesPagger({ page: this.page, limit: this.limit }, this.sortOrder, this.sortByField);
	}

	getAllRolesPagger(paggerObj: any, pssortOrder: any, pssortByField: any) {
		this.caseService.getcaseResultFilesPagger(paggerObj, pssortOrder, pssortByField).subscribe(
			data => {
				// console.log("data", data);

				if (data["isData"]) {
					// console.log("sss", data);
					this.modelTemp = data["data"];				
					this.getAllListById(this.modelTemp);					
				}
			},
			error => {
				console.log(error);
			});
	}

	openModalSec(content) {
		let ngbModalOptions: NgbModalOptions = {
			backdrop: 'static',
			keyboard: false,
			size: 'lg'
		};
		this.modalColumnRefSec = this.modalService.open(content, ngbModalOptions);
	}
	finishDownload() {
		this.caseService.finishDownload(this.caseId).subscribe(
			data => {
				let returnData = data;
				this.modalColumnRefSec.close();	
				this.model.isDown = 'Y';
				window.location.reload();
				// this.router.navigate(['/case-management/case-detail/' + this.caseId]);
			},
			error => {
				console.log("error", error);
			});
	}
	cancelopenModalSecColumnModal() {
		
		this.modalColumnRefSec.close();
	}
	appLoadingOverlay(psElem, psMessage, psType) {
		if (psType == 'block') {
			mApp.block(psElem, {
				overlayColor: '#000000',
				type: 'loader',
				state: 'success',
				message: psMessage
			});
		} else {
			mApp.unblock(psElem);
		}
	
	}
}