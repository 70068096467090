import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'generateKeyValueObject'
})
export class GenerateKeyValueObjectPipe implements PipeTransform {
	transform(value: any, args?: any): any {
		let myObj = value;
		let keys = [];
	for (let key in value) {
		keys.push({ key: key, value: value[key]});
	  }
		console.log("returnArray ", keys);
		return keys;
		
		
	}
}
