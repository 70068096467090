import { Pipe, PipeTransform } from '@angular/core';
import { CaseService } from '../services/app/case.service';

@Pipe({
	name: 'subServicePipe'
})
export class SubServicePipe implements PipeTransform {
	constructor( private caseService: CaseService) { }
	transform(value: any, args?: any): any {
		console.log("value", value);
		let value1=	{
			"API_KEY":"",
			"reference_type": "sub_services",
			"id"	: value,
			"time_stamp":"2019-12-12 23:59:59"
		}
		let arrayObj = [];
		// this.caseService.loadDropDown(value).subscribe(
		// 	data => {
		// 		if (data) {
		// 			console.log("dropdown", data);
		// 			let bodyValue = data["body"];
		// 			let jsonValue = JSON.parse(bodyValue);
		// 			let subcaseserviceTypeDropDown = jsonValue["dropdown_details"];
		// 			for (let i = 0; i < subcaseserviceTypeDropDown.length; i++){
		// 				let Array = 
		// 					{Id:subcaseserviceTypeDropDown[i].Id ,Name:subcaseserviceTypeDropDown[i].Name,value:false}
		// 					this.array1.push(Array);
		// 			}
		// 			this.model.services = this.array1;
					
		// 			console.log("dropdownservice1", this.array1);
		// 		}
		// 	});	
		return arrayObj;
		//return arrKeys;
		
	}
	// transform(value: any, args?: any): any {
	// 	return console.log(value);
	// 	//return "console";
	// }
	// loadDropDown(projectObj) {
	// 	localStorage.setItem("AWS", 'AWS');
	// 	let url = 'https://3zje9z5nb2.execute-api.ap-south-1.amazonaws.com/loadingDropdowns';
	// 	return this.http.post(url,
	// 		projectObj).pipe(
	// 			map((response: Response) => response));
	// }
}
