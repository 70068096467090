import { Injectable, ErrorHandler } from "@angular/core";
import { Http, Headers, Response } from '@angular/http';
import { AuthenticationService } from '../../auth/authentication.service';
import { AppSettings } from '../../../core/models/app.constants';
import { Observable } from 'rxjs';
import { map, catchError, tap, switchMap } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';

@Injectable()
export class SupportService {

	constructor(
		private http: HttpClient,
		private authenticationService: AuthenticationService
	) { }

	listQueries(pstrFilters: any) {
		console.log("data2", pstrFilters);
		let url = AppSettings.API_ENDPOINT + 'app/queries/getQueryList?pcd=tz';
		// return this.http.get(url, this.authenticationService.jwt()).pipe(
		// 	map((response: Response) => response.json()));
		console.log("fil", pstrFilters);
		return this.http.post(url,
			JSON.stringify(pstrFilters)).pipe(
				map((response: Response) => response));
		// return this.http.post(url,
		// 	JSON.stringify(queryObj)).pipe(
		// 		map((response: Response) => response));

	}

	listQueriesNew(queryObj: any) {
		let url = AppSettings.API_ENDPOINT + 'app/queries/getQueryList.json?pcd=tz';
		return this.http.post(url, JSON.stringify(queryObj));
	}
	screenFilterVO() {
		let url = AppSettings.API_ENDPOINT + 'app/queries/getScreenFilterForQuery';
		return this.http.get(url);

	}
	getPagger(paggerObj: any, pssortOrder: any, pssortByField: any) {
		let pageObj = JSON.stringify(paggerObj);
		let url = AppSettings.API_ENDPOINT + 'app/queries/getPaggination/' + pageObj + '/' + pssortOrder + '/' + pssortByField;
		console.log("API Test", url);
		return this.http.get(url);
	}
	saveQuery(projectObj: any) {
		let url = AppSettings.API_ENDPOINT + 'app/queries/saveCaseQuery.json?pcd=tz';
		return this.http.post(url,
			JSON.stringify(projectObj)).pipe(
				map((response: Response) => response));
	}
	openQueries(qryId, callBackScreen) {
		let url = AppSettings.API_ENDPOINT + 'app/queries/getCaseQueries?caseQueryId=' + qryId+ '&callbackScreen=' + callBackScreen;
		return this.http.get(url);
	}
	saveQueryRes(projectObj: any) {
		let url = AppSettings.API_ENDPOINT + 'app/queries/saveCaseQueryResponse.json?pcd=tz';
		return this.http.post(url,
			JSON.stringify(projectObj)).pipe(
				map((response: Response) => response));
	}
	listCaseQueries(caseId) {
		let url = AppSettings.API_ENDPOINT + 'app/queries/getCaseQueryList?caseId=' + caseId;
		return this.http.get(url);
	}
	getStatus(){
		let url = AppSettings.API_ENDPOINT + 'app/utils/getStatus';
		return this.http.get(url);
	}
	searchCaseDetails(serchTest) {
		let url = AppSettings.API_ENDPOINT + 'app/cases/getCaseDetailsList?pstrCaseName='+serchTest;
		return this.http.get(url);
	}

}