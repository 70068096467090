import { AuthenticationService } from '../../../../../core/auth/authentication.service';
import {ChangeDetectorRef, ChangeDetectionStrategy, Component, ElementRef, HostBinding, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { DashboardService } from '../../../../../core/services/app/dashboard.service';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { CaseManagementModule} from '../../../../pages/components/case-management/case-management.module';
@Component({
	selector: 'm-user-profile',
	templateUrl: './user-profile.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserProfileComponent implements OnInit {
	@HostBinding('class')
	// tslint:disable-next-line:max-line-length
	classes = 'm-nav__item m-topbar__user-profile m-topbar__user-profile--img m-dropdown m-dropdown--medium m-dropdown--arrow m-dropdown--header-bg-fill m-dropdown--align-right m-dropdown--mobile-full-width m-dropdown--skin-light';

	@HostBinding('attr.m-dropdown-toggle') attrDropdownToggle = 'click';

	@Input() avatar: string = './assets/app/media/img/users/download.png';
	@Input() avatarBg: SafeStyle = '';

	@ViewChild('mProfileDropdown') mProfileDropdown: ElementRef;

	modelUser: any;
	model: any = {};
	public modalColumnRef: any;
	selectedbox: { [key: number]: boolean; } = {};
	feedbackProceed: any = false;
	constructor (
		private router: Router,
		private authService: AuthenticationService,
		private sanitizer: DomSanitizer,
		private dashboardService: DashboardService,
		private _ref: ChangeDetectorRef,
		private modalService: NgbModal,
	) {}

	ngOnInit (): void {
		if (!this.avatarBg)
			this.avatarBg = this.sanitizer.bypassSecurityTrustStyle('url(./assets/app/media/img/misc/user_profile_bg.jpg)');
}

	public logout () {
		// /this.authService.logout(true);
		//localStorage.clear();
		
		this.router.navigate(['/login']);
		//window.location.reload();
		this. _ref.detectChanges();
	}
	viewFeedBackList(content) {
		this.dashboardService.getFeedBackCases().subscribe(
			data => {
				let returnData = data;
				this.model = returnData["dataList"][0];
				// console.log("feedback", this.model);
				this._ref.detectChanges();
			},
			error => {
				console.log("error", error);
			}); 
			let ngbModalOptions: NgbModalOptions = {
				backdrop: 'static',
				keyboard: false,
			
			};
			this.modalColumnRef = this.modalService.open(content, ngbModalOptions);
	}
	cancelUpdateColumnModal(editCol: any) {
		this.modalColumnRef.close();
		this.selectedbox["key"] ="" ;
	}
	onCheckedChange(i: number, $event) {
		this.selectedbox[i] = $event;
		// console.log("slave $event = " + $event);
		// console.log("this.selectedSlaves ", this.selectedbox);
	}
	proceed() {
		this.feedbackProceed = true;
		let objKeys = Object.keys(this.selectedbox);
		let trueObj = objKeys.filter(a => (this.selectedbox[a]));
		// console.log("trueObj = " + trueObj);
		this.modalColumnRef.close();
		//this.router.navigate(['/case-management/cases/feedBack/' + trueObj]);
		this.selectedbox["key"] ="" ;
		
	}

	cancel() {
		this.modalColumnRef.close();
	}
}
