import { Injectable, ErrorHandler } from "@angular/core";
import { Http, Headers, Response } from '@angular/http';
import { AuthenticationService } from '../../auth/authentication.service';
import { AppSettings } from '../../../core/models/app.constants';
import { Observable, Subject } from 'rxjs';
import { map, catchError, tap, switchMap } from 'rxjs/operators';
import { Router, NavigationStart } from '@angular/router';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';

@Injectable()
export class DashboardService {
	private subject = new Subject<any>();

	constructor(
		private http: HttpClient,
		private authenticationService: AuthenticationService,
		private router: Router
	) {
		router.events.subscribe(event => {
			if (event instanceof NavigationStart) {
				this.subject.next();
			}
		});
	}

	listAnnouncements() {
		let url = AppSettings.API_ENDPOINT + 'app/admin/announcements';
		return this.http.get(url);		
	}
	caseMetrics() {
		let url = AppSettings.API_ENDPOINT + 'app/admin/caseMetrics';
		return this.http.get(url);		

	}
	latestCases() {
		let url = AppSettings.API_ENDPOINT + 'app/admin/latestCases';
		return this.http.get(url);	
	}
	deliveredCases() {
		let url = AppSettings.API_ENDPOINT + 'app/admin/deliveredCases';
		return this.http.get(url);	
	}

	latestSupportMessages() {
		let url = AppSettings.API_ENDPOINT + 'app/admin/listQueries';
		return this.http.get(url);
	}

	getVideoLink() {
		let url = AppSettings.API_ENDPOINT + 'app/admin/videoLink';
		return this.http.get(url);
	} 
	getuserProfile() {
		let url = AppSettings.API_ENDPOINT + 'app/userProfile/getUserProfile';
		return this.http.get(url);		
	}
	getTimeZone() {
		let url = AppSettings.API_ENDPOINT + 'app/utils/gettimeZoneList';
		return this.http.get(url);		
	}
	
	getTimeZoneFormat() {
		let url = AppSettings.API_ENDPOINT + 'app/utils/gettimeZoneFormatListt';
		return this.http.get(url);		
	}
	saveUserProfile(projectObj: any) {
		let url = AppSettings.API_ENDPOINT + 'app/userProfile/updateUserProfile';
		return this.http.post(url,
			JSON.stringify(projectObj)).pipe(
				map((response: Response) => response));
	}
	savePassword(projectObj: any) {
		let url = AppSettings.API_ENDPOINT + 'app/userProfile/changePassword';
		return this.http.post(url,
			JSON.stringify(projectObj)).pipe(
				map((response: Response) => response));
	}
	logoutDevice() {
		let url = AppSettings.API_ENDPOINT + 'app/userProfile/getlogoutDevices';
		return this.http.get(url);		
	}
	getFeedBackCases() { 
		let url = AppSettings.API_ENDPOINT + 'app/feedback/getDeliveredCasesListForFeedBack';
		return this.http.get(url);
	}
	viewTour() { 
		let url = AppSettings.API_ENDPOINT + 'app/feedback/getDeliveredCasesListForFeedBack';
		return this.http.get(url);
	}
	
	
}