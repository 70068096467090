import { NgModule, CUSTOM_ELEMENTS_SCHEMA,Component  } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { AddAdditionalRecordComponent } from '../content/pages/components/case-management/add-additional-record/add-additional-record.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
// import { CaseFeedbackComponent } from '../content/pages/components/case-management/feedback/case-feedback.component';
import { CaseFeedbackComponent } from '../content/pages/components/case-management/feedback/case-feedback.component';
import { AddAdditionalRecordsDemoComponent } from '../content/pages/components/case-management/add-additional-records-demo/add-additional-records-demo.component';
import { CompletedFileListComponent } from '../content/pages/components/case-management/result-file/completed-file-list.component';
import { IconforS3downloadComponent } from '../content/pages/components/case-management/iconfor-s3download/iconfor-s3download.component';
import { NgbModalModule, NgbAlertModule, NgbTooltipModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { PaginationComponent } from '../content/pages/components/pagination/pagination.component';
@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		RouterModule,
		NgMultiSelectDropDownModule.forRoot(),
		NgbModule.forRoot()
	],
    exports: [
		AddAdditionalRecordComponent,
		NgMultiSelectDropDownModule,
		CaseFeedbackComponent,
		CompletedFileListComponent,
		IconforS3downloadComponent,
		NgbModule,
		PaginationComponent
    ],
	declarations: [
		AddAdditionalRecordComponent,
		CaseFeedbackComponent,
		CompletedFileListComponent,
		IconforS3downloadComponent,
		PaginationComponent
	],
	providers: [],
	schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class SharedModule { } {
}