import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { BehaviorSubject, Observable, Subject, from, throwError, config } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../auth/auth.service';
import { map, catchError, tap, switchMap } from 'rxjs/operators';
import { TokenStorage } from '../auth/token-storage.service';
import { AclService } from '../services/acl.service';
@Injectable()
export class AuthGuard implements CanActivate {

	constructor(
		private _router: Router,
		private authService: AuthService,
		private ngxToastrService: ToastrService,
		private tokenStorage: TokenStorage,
		private aclService: AclService
	) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
		let currentUser = JSON.parse(localStorage.getItem('currentUser'));
		let roles = this.tokenStorage.getUserRoles();
		let accToken = this.tokenStorage.getAccessToken();
		if (accToken != null && accToken && roles) {
			this.aclService.setCurrrentUserRoles(roles);
			return true;
		}
		this._router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
		return false;
        // return this.authService.verify().pipe(map(
        //     data => {
        //         if (localStorage.getItem('currentUser')) {
        //             // logged in so return true
        //             return true;
        //         }

        //         // not logged in so redirect to login page with the return url
        //         this._router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
        //         return false;
        //     },
        //     error => {
        //         // error when verify so redirect to login page with the return url
        //         this._router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
        //         return false;
        //     }));
    }

}