import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'generateServiceObject'
})
export class GenerateServiceObjectPipe implements PipeTransform {
	transform(value: any, args?: any): any {
		let myObj = value;
		let keys = [];
		let arrKeys = Object.keys(myObj);
		console.log("returnArray ", arrKeys);
		return arrKeys;
		
		
	}
}
