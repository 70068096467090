import { Component, OnInit,Input } from '@angular/core';
import { UploadFileService } from '../../../../../core/services/app/upload-file.service';
declare let mApp: any;
@Component({
  selector: 'm-iconfor-s3download',
  templateUrl: './iconfor-s3download.component.html',
  styleUrls: ['./iconfor-s3download.component.scss']
})
export class IconforS3downloadComponent implements OnInit {
	@Input() fileLocation: String;
	@Input() fileName: String;
	@Input() fileType: String;
	@Input() fileCaseId: String;
	
	constructor(private uploadService: UploadFileService,) {
	  
   }

  ngOnInit() {
  }

DownloadedFiles() {
	this.appLoadingOverlay("#file-downloading", "File Downloading...", "block");
	this.uploadService.downloadFilesFromS3(this.fileLocation, this.fileName, this.fileType, this.fileCaseId);
	this.appLoadingOverlay("#file-downloading", "", "unblock");
 }	
 appLoadingOverlay(psElem, psMessage, psType) {
	if (psType == 'block') {
		mApp.block(psElem, {
			overlayColor: '#000000',
			type: 'loader',
			state: 'success',
			message: psMessage
		});
	} else {
		mApp.unblock(psElem);
	}

	}

	
	
	
}
