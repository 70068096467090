import { AfterViewInit, ChangeDetectionStrategy, Input, Output, Component, ElementRef, HostBinding, OnInit,TemplateRef,ViewChild,ChangeDetectorRef } from '@angular/core';
import { HeaderDirective } from '../../../core/directives/header.directive';
import { HeaderService } from '../../../core/services/layout/header.service';
import { NavigationCancel, NavigationEnd, NavigationStart, RouteConfigLoadEnd, RouteConfigLoadStart, Router } from '@angular/router';
import { LayoutRefService } from '../../../core/services/layout/layout-ref.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';

// import { AddAdditionalRecordComponent } from '../../pages/components/case-management/add-additional-record/add-additional-record.component';

@Component({
	selector: 'm-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent implements OnInit, AfterViewInit {
	@HostBinding('class') classes = 'm-grid__item m-header';
	@HostBinding('id') id = 'm_header';
	@HostBinding('attr.m-minimize-offset') attrMinimizeOffset = '200';
	@HostBinding('attr.m-minimize-mobile-offset') attrMinimizeMobileOffset = '200';

	/**
	 * Hack way to call directive programatically within ts file instead of tag in HTML
	 * https://stackoverflow.com/questions/41298168/how-to-dynamically-add-a-directive
	 * The official feature support is still pending
	 * https://github.com/angular/angular/issues/8785
	 */
	@HostBinding('attr.mHeader') mHeader: HeaderDirective;
	public modalColumnRef: any;
	public modalProjectRef: any;
	public modalProjectRefInit: any;
	public caseList: any;
	model: any = {};
	encryptId: any;
	isAddFile: boolean = false;
	feedbackProceed: any = false;
	selectedCases: any = [];
	isSelectedCases: any = false;
	
	hidefeedback = true;
	@Input() action: string;
	@Output() actionChange = new Subject<string>();
	@ViewChild('openFeedBackListHeader') private templates: TemplateRef<any>;
	@ViewChild('viewFeedbackCasesModalRemindMeIntial') private Inittemplates: TemplateRef<any>;
	selectedbox: { [key: number]: boolean; } = {};
	//public remainder: any;
	feedbackRemainder: any;
	noRecords: boolean = true;
	constructor(
		private el: ElementRef,
		private router: Router,
		private layoutRefService: LayoutRefService,
		public headerService: HeaderService,
		public loader: LoadingBarService,
		private modalService: NgbModal,
		private _ref: ChangeDetectorRef,
	) {
		// page progress bar percentage
		this.router.events.subscribe(event => {
			if (event instanceof NavigationStart) {
				// set page progress bar loading to start on NavigationStart event router
				this.loader.start();
			}
			if (event instanceof RouteConfigLoadStart) {
				this.loader.increment(35);
			}
			if (event instanceof RouteConfigLoadEnd) {
				this.loader.increment(75);
			}
			if (event instanceof NavigationEnd || event instanceof NavigationCancel) {
				// set page progress bar loading to end on NavigationEnd event router
				this.loader.complete();
			}
		});
	}

	ngOnInit(): void {
	//	this.viewFeedBackList();
		let key1 = localStorage.getItem("key2");
		if (key1 == 'false') {
			this.hidefeedback = false;
			
		}
	}

	ngAfterViewInit(): void {
		setTimeout(() => {
			this.mHeader = new HeaderDirective(this.el);
			// manually call the directives' lifecycle hook method
			this.mHeader.ngAfterViewInit();
		});

		// keep header element in the service
		this.layoutRefService.addElement('header', this.el.nativeElement);
	}

	openModalAdd(content) {
		this.caseSearchDetailsInitiate("");
		let ngbModalOptions: NgbModalOptions = {
			backdrop: 'static',
			keyboard: false,
			size: 'lg'
		};
		this.modalColumnRef = this.modalService.open(content, ngbModalOptions);
	}
	cancelUpdateColumnModalAddtion() {
		//this.modalProjectRef.close();
		 this.modalColumnRef.close();
		this.model["caseName"] = "";
		this.caseList = [];
		this.isAddFile = false;
		//this.modalProjectRef.close();
	
		
	}

	cancelUpdateColumnModalSecond() {
		this.model["caseName"] = "";
		this.caseList = [];
		this.isAddFile = false;
		this.modalProjectRef.close();
	}

	caseSearchDetails(event, value) {
		
		let serchTest = value;
		if (serchTest.length > 3) {
			this.headerService.searchCaseDetails(serchTest).subscribe(
				data => {
					let returnData = data;
					if (returnData["isData"]) {
						this.caseList = returnData["dataList"][0];
						// console.log("hlo", this.caseList);
					}
					if (this.caseList.length > 0) {
						this.noRecords = false;
					} else {
						this.noRecords = true;
					}
					//this._ref.detectChanges();
				},
				error => {
					// console.log("error", error);
				});
		}
	}

	caseSearchDetailsInitiate(value) {		
		let serchTest = "";
			this.headerService.searchCaseDetailsInitiate(serchTest).subscribe(
				data => {
					let returnData = data;
					if (returnData["isData"]) {
						this.caseList = returnData["dataList"][0];
						// console.log("hlo", this.caseList);
					}
					if (this.caseList.length > 0) {
						this.noRecords = false;
					}else {
						this.noRecords = true;
					}
					//this._ref.detectChanges();
				},
				error => {
					// console.log("error", error);
				});
	}

	getMoreDetails(caseName) {		
		let serchTest = caseName;
		let getMore = "";
			this.headerService.searchCaseDetailsInitiateMore(serchTest,getMore).subscribe(
				data => {
					let returnData = data;
					if (returnData["isData"]) {
						this.caseList = returnData["dataList"][0];
						// console.log("hlo", this.caseList);
					}
					if (this.caseList.length > 0) {
						this.noRecords = false;
					}else {
						this.noRecords = true;
					}
					//this._ref.detectChanges();
				},
				error => {
					// console.log("error", error);
				});
		
	}
	openModalAddNew(content, encrpt) {
		this.isAddFile = true;
		this.encryptId = encrpt;
		// let ngbModalOptions: NgbModalOptions = {
		//     backdrop: 'static',
		// 	keyboard: false,
		// 	size:'lg'
		// };
		// this.modalColumnRef = this.modalService.open(content, ngbModalOptions);	
	}
	cancelUpdateColumnModalAddtionNew(editCol: any) {
		 this.modalColumnRef.close();
		//this.modalProjectRef.close();
		
	}
	cancelUpdateColumnModalFeed(editCol: any) {
		// this.modalColumnRef.close();
		this.modalProjectRef.close();
		
	}
	cancelUpdateColumnModalFeedNew(editCol: any) {
		// this.modalColumnRef.close();
		this.modalProjectRef.close();
		
	}

	backAddFileHead() {
		this.isAddFile = false;
	}

	saveAdditionFile(emitValue) {
		if (emitValue["save"]) {
			this.modalColumnRef.close();
		}
	}

	viewFeedBackList() {
		this.headerService.getFeedBackCasesHeader().subscribe(
			data => {
				let returnData = data;
				this.model = returnData["dataList"][0];	
				var remainder = JSON.parse(localStorage.getItem('ReminderFirst'));
				if (remainder == null || remainder== undefined){
					localStorage.setItem('ReminderFirst', "true");
					let ngbModalOptions: NgbModalOptions = {
						backdrop: 'static',
						keyboard: false,
					
					};
					this.modalProjectRef = this.modalService.open(this.templates, ngbModalOptions);
				}
				// if (returnData["dataList"][0].length > 0) {
				// 	localStorage.setItem('ReminderFirst', "true");
				// 	let ngbModalOptions: NgbModalOptions = {
				// 		backdrop: 'static',
				// 		keyboard: false,
					
				// 	};
				// 	this.modalProjectRef = this.modalService.open(this.templates, ngbModalOptions);
				// }	
			},
			error => {
				// console.log("error", error);
			});		
	}

	
	proceeds() {
		// this.feedbackProceed = true;
		// let objKeys = Object.keys(this.selectedbox);
		// let trueObj = objKeys.filter(a => (this.selectedbox[a]));
		// console.log("trueObj = " + trueObj);
		// this.modalColumnRef.close();
		// this.router.navigate(['/case-management/cases/feedBack/' + trueObj]);
		// this.selectedbox["key"] ="" ;
		localStorage.removeItem("feedbackcases");
		this.selectedCases = [];
		this.feedbackProceed = true;
		let objKeys = Object.keys(this.selectedbox);
		let trueObj = objKeys.filter(a => (this.selectedbox[a]));
		// console.log("objKeys", objKeys);
		// console.log("this.selectedbox", this.selectedbox);
		for (let index = 0; index < objKeys.length; index++) {
			if (this.selectedbox[objKeys[index]]) {
				var tempObj = {};
				tempObj["encryptedId"] = objKeys[index];
				this.selectedCases.push(tempObj);
			}
		}
		// console.log("this.selectedCases", this.selectedCases);
		this.isSelectedCases = false;
		if (this.selectedCases.length > 0) {
			this.isSelectedCases = true;
		}
		localStorage.setItem("feedbackcases",JSON.stringify(this.selectedCases));
		// console.log(" this.selectedCases ", this.selectedCases)
		this.selectedbox["key"] = "";
		this.modalProjectRef.close();
		this.router.navigate(['/case-management/commonfeedback/']);
					
		
		
		
		
		
	}
	cancel() {
		 this.modalProjectRef.close();
		// this.modalProjectRef.close();
		//this.modalColumnRef.close();
		// this.model["caseName"] = "";
		// this.caseList = [];
		// this.isAddFile = false;
	}


	onCheckedChange(i: number, $event) {
		this.selectedbox[i] = $event;
		//console.log("slave $event = " + $event);
		//console.log("this.selectedSlaves ", this.selectedbox);
	}

	ReminderCancel1() {
		var preJSON = {};
		preJSON["start"] = false;
		localStorage.setItem('Reminder', JSON.stringify(preJSON));
		var remainder = JSON.parse(localStorage.getItem('Reminder'));
		if (remainder != null) {
			clearInterval(this.feedbackRemainder);
		}		
	}

	FeedBackRemindFirst() {		
		this.modalProjectRef.close();
		let ngbModalOptions: NgbModalOptions = {
			backdrop: 'static',
			keyboard: false,
		
		};
		this.modalProjectRefInit = this.modalService.open(this.Inittemplates, ngbModalOptions);		
		this.FeedBackRemindMe();
	}
	
	FeedBackRemindMe() {
		var remainder = JSON.parse(localStorage.getItem('Reminder'));
		if (remainder != null) {
			localStorage.removeItem('Reminder');
		}
		var preJSON = {};
		preJSON["startTime"] = new Date();
		preJSON["interval"] = "1";
		preJSON["mode"] = "M";
		preJSON["start"] = true;
		localStorage.setItem('Reminder', JSON.stringify(preJSON));
		
		this.setIntrvalForReminder();
	}
	setIntervalToPopulatePop() {
		try {
			var remainder = JSON.parse(localStorage.getItem('Reminder'));
			if (remainder != null) {
				var timeDate = new Date(remainder.startTime);
				var getInterval = remainder.interval;
				var getMode = remainder.mode;
				// console.log("timeDate>>>>", timeDate);
				switch (getMode) {
					case 'H':
						timeDate.setTime(timeDate.getTime() + 5 * 60 * 3600000);
						break;
					case 'M':
						timeDate.setTime(timeDate.getTime() + 5 * 60 * 1000);
						break;
					case 'MN':
						timeDate.setTime(timeDate.getTime() + 5 * 60 * 1000);
						break;
					default:
						timeDate = undefined;
						break;
				}
				var curDate = new Date();
				var strDate = timeDate.getHours() + ":" + timeDate.getSeconds()
					+ ":" + timeDate.getSeconds();
				var endDate = curDate.getHours() + ":" + curDate.getSeconds()
					+ ":" + curDate.getSeconds();
					// console.log("validate>>>>", strDate == endDate);
				if (strDate == endDate) {
					var preJSON = {};
					preJSON["start"] = false;
					localStorage.setItem('Reminder', JSON.stringify(preJSON));
					clearInterval(this.feedbackRemainder);						
					this.viewFeedBackList();
				}					
			}
		} catch (ex) {
			console.error('outer', ex.message);
		}
	}
	setIntrvalForReminder() {
		this.feedbackRemainder = setInterval(() => this.setIntervalToPopulatePop(), 1000);		
	}
	proceedIntial() {
		this.modalProjectRef.close();
		this.modalProjectRefInit.close();
	}
	cancelInitial() {
		this.modalProjectRefInit.close();
	}
	
}	
