import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'mConsoleLog'
})
export class ConsoleLogPipe implements PipeTransform {
	transform(value: any, args?: any): any {
		let myObj = value;
		let returnArray = [];
		let arrKeys = Object.keys(myObj);
		console.log("returnArray ", arrKeys);
		return arrKeys;
		
		
	}
	// transform(value: any, args?: any): any {
	// 	return console.log(value);
	// 	//return "console";
	// }
}
