import { BehaviorSubject, Observable, Subject, from, throwError, config } from 'rxjs';
import { map, catchError, tap, switchMap } from 'rxjs/operators';
import { Http, Headers, Response, RequestOptions } from "@angular/http";

import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpRequest } from '@angular/common/http';
import { AuthService } from 'ngx-auth';

import { TokenStorage } from './token-storage.service';
import { UtilsService } from '../services/utils.service';
import { AccessData } from './access-data';
import { Credential } from './credential';
import { AclService } from '../services/acl.service';

import { AppConstantConfig } from '../../config/app.constant';
import { AnyMxRecord } from 'dns';

@Injectable()
export class AuthenticationService implements AuthService {
	API_URL = 'api';
	API_ENDPOINT_LOGIN = '/login';
	API_ENDPOINT_REFRESH = '/refresh';
	API_ENDPOINT_REGISTER = '/register';

	public onCredentialUpdated$: Subject<AccessData>;
	public onCredentialUpdatedLogin$: Subject<any>;
	public onAuthUpdated$: BehaviorSubject<any>;

	constructor(
		private http: HttpClient,
		private tokenStorage: TokenStorage,
		private util: UtilsService,
		private aclService: AclService
	) {
		this.onCredentialUpdated$ = new Subject();
		this.onCredentialUpdatedLogin$ = new Subject();
		this.onAuthUpdated$ = new BehaviorSubject({});

		this.onAuthUpdated$.subscribe(accdata => {
			this.aclService.setCurrrentUserRoles(accdata["roles"]);
		});

		this.getUserRoles().subscribe(roles => {
			this.aclService.setCurrrentUserRoles(roles);
		});
	}

	/**
	 * Check, if user already authorized.
	 * @description Should return Observable with true or false values
	 * @returns {Observable<boolean>}
	 * @memberOf AuthService
	 */
	public isAuthorized(): Observable<boolean> {
		return this.tokenStorage.getAccessToken().pipe(map(token => !!token));
	}

	/**
	 * Get access token
	 * @description Should return access token in Observable from e.g. localStorage
	 * @returns {Observable<string>}
	 */
	public getAccessToken(): Observable<string> {
		return this.tokenStorage.getAccessToken();
	}

	/**
	 * Get user roles
	 * @returns {Observable<any>}
	 */
	public getUserRoles(): Observable<any> {
		return this.tokenStorage.getUserRoles();
	}

	/**
	 * Function, that should perform refresh token verifyTokenRequest
	 * @description Should be successfully completed so interceptor
	 * can execute pending requests or retry original one
	 * @returns {Observable<any>}
	 */
	public refreshToken(): Observable<AccessData> {
		return this.tokenStorage.getRefreshToken().pipe(
			switchMap((refreshToken: string) => {
				return this.http.get<AccessData>(this.API_URL + this.API_ENDPOINT_REFRESH + '?' + this.util.urlParam(refreshToken));
			}),
			tap(this.saveAccessData.bind(this)),
			catchError(err => {
				this.logout();
				return throwError(err);
			})
		);
	}

	/**
	 * Function, checks response of failed request to determine,
	 * whether token be refreshed or not.
	 * @description Essentialy checks status
	 * @param {Response} response
	 * @returns {boolean}
	 */
	public refreshShouldHappen(response: HttpErrorResponse): boolean {
		return response.status === 401;
	}

	/**
	 * Verify that outgoing request is refresh-token,
	 * so interceptor won't intercept this request
	 * @param {string} url
	 * @returns {boolean}
	 */
	public verifyTokenRequest(url: string): boolean {
		return url.endsWith(this.API_ENDPOINT_REFRESH);
	}

	/**
	 * Submit login request
	 * @param {Credential} credential
	 * @returns {Observable<any>}
	 */
	public login(credential: Credential): Observable<any> {
		let _body = JSON.stringify({ loginId: credential.email, password: credential.password });
		// console.log("_body", _body);
		let headers = new HttpHeaders({ 'Content-Type': 'application/json' })

		let url = AppConstantConfig.API_ENDPOINT_AUTH + 'login';
		//let url = 'http://app-env-portal.iajrkaw4g9.ap-south-1.elasticbeanstalk.com/auth/login';

		//let url = 'http://localhost:8080/trivent/auth/login';

		//let url = 'http://localhost:8080/trivent/auth/login';
		//let url = 'http://appserver-live-3tier.iajrkaw4g9.ap-south-1.elasticbeanstalk.com/auth/login';

		//let url = 'http://192.168.2.18:8080/portal/';
		//let url = 'https://192.168.2.17/portal/';
		//	let url = 'api/portal/auth/login';
		var accData = {};
		//return this.http.get<AccessData>(this.API_URL + this.API_ENDPOINT_LOGIN + '?' + this.util.urlParam(credential)).pipe(
			//this.http.post(url, projectObj).pipe( map((response: Response) => response));
		return this.http.post(url, _body).pipe(
//<AccessData>
			// 	map((result: any) => {
			// 		if (result instanceof Array) {
			// 			return result.pop();
			// 		}
			// 		return result;
			// 	}),
			map((result: any) => {
				// console.log("login result", result);
				// console.log("login result message", result.message);
				//remove msg
				localStorage.removeItem('Message');
				if (result.message) {
					//set msg
					localStorage.setItem("Message", result.message);
				}
				if (result && result.accessToken && result.accessToken != "" && result.roles) {
					// console.log("login result", result);
					// console.log("login result message", result.message);
					// accData["accessToken"] = "access-token-" + result["token"]
					// accData["refreshToken"] = "access-token-" + result["token"];
					// accData["roles"] = ["ADMIN"];
					this.tokenStorage
						.setAccessToken(result.accessToken)
						.setRefreshToken(result.refreshToken)
						.setUserRoles(result.roles);
					//this.onCredentialUpdatedLogin$.next(accData);
					this.aclService.setCurrrentUserRoles(result.roles);
					//this.onAuthUpdated$.next(accData);
					return true;
				}
				return false;
			}),
			//tap(this.saveAccessData.bind(this)),
			catchError(this.handleError('login', []))
		);
	}

	/**
	 * Handle Http operation that failed.
	 * Let the app continue.
	 * @param operation - name of the operation that failed
	 * @param result - optional value to return as the observable result
	 */
	private handleError<T>(operation = 'operation', result?: any) {
		return (error: any): Observable<any> => {
			// TODO: send the error to remote logging infrastructure
			console.error(error); // log to console instead

			// Let the app keep running by returning an empty result.
			return from(result);
		};
	}

	/**
	 * Logout
	 */
	public logout(refresh?: boolean): void {
		this.tokenStorage.clear();
		// console.log("log1", refresh)
		if (refresh) {
			location.reload(true);
			// console.log("log2", refresh)
		}
	}

	/**
	 * Save access data in the storage
	 * @private
	 * @param {AccessData} data
	 */
	public saveAccessData(accessData: AccessData) {

		if (typeof accessData !== 'undefined') {
			this.tokenStorage
				.setAccessToken(accessData.accessToken)
				.setRefreshToken(accessData.refreshToken)
				.setUserRoles(accessData.roles);
			// console.log("saveAccessData ", accessData);
			this.onCredentialUpdated$.next(accessData);
		}
	}

	/**
	 * Submit registration request
	 * @param {Credential} credential
	 * @returns {Observable<any>}
	 */
	public register(credential: Credential): Observable<any> {
		// dummy token creation
		credential = Object.assign({}, credential, {
			accessToken: 'access-token-' + Math.random(),
			refreshToken: 'access-token-' + Math.random(),
			roles: ['USER'],
		});
		return this.http.post(this.API_URL + this.API_ENDPOINT_REGISTER, credential)
			.pipe(catchError(this.handleError('register', []))
			);
	}

	/**
	 * Submit forgot password request
	 * @param {Credential} credential
	 * @returns {Observable<any>}
	 */
	public requestPassword(credential: Credential): Observable<any> {
		return this.http.get(this.API_URL + this.API_ENDPOINT_LOGIN + '?' + this.util.urlParam(credential))
			.pipe(catchError(this.handleError('forgot-password', []))
			);
	}


	/*
		New Login API
	*/
	public loginAPI(email: string, password: string) {


		let _body = JSON.stringify({ loginId: email, password: password });
		// console.log("_body", _body);
		let headers = new HttpHeaders({ 'Content-Type': 'application/json' })

		let url = AppConstantConfig.API_ENDPOINT_AUTH + 'login';

		return this.http.post(url, _body, { headers }).pipe(
			map((response: Response) => {
				// console.log("response ", response);
				var user = response;
				if (user && user["token"] && user["token"] != null) {
					localStorage.setItem('currentUser', JSON.stringify(user));
					var accData = {};
					accData["accessToken"] = "access-token-" + user["token"]
					accData["refreshToken"] = "access-token-" + user["token"];
					accData["roles"] = ["ADMIN"];
					this.tokenStorage
						.setAccessToken(accData["accessToken"])
						.setRefreshToken(accData["refreshToken"])
						.setUserRoles(accData["roles"]);
					this.onCredentialUpdated$.next.bind(accData);
					return true;
				}
				return false;
			})
			//tap(this.saveAccessData.bind(this)),
			//catchError(this.handleError('login', []))
		);


	}
	public jwt() {
		let currentUser = localStorage.getItem('accessToken');
		currentUser = currentUser.split("access-token-")[1];
		if (currentUser) {
			let headers = new HttpHeaders({ 'Access-Control-Allow-Origin': '*' });
			headers.append('Content-Type', 'application/json');
			headers.append('Authorization', 'Bearer ' + currentUser);
			//return new RequestOptions({ headers: headers });
			return { headers: headers };
		}
	}

}
