import { Input, OnInit, ViewEncapsulation,Output,EventEmitter, OnChanges, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CaseService } from './../../../../../core/services/app/case.service';
import { NgbRatingConfig } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Toastr } from '../../../../../config/Toastr';
import { FeedBack } from '../../../../../config/feedback';
import { Component } from '@angular/core';
import { DatePipe } from '@angular/common';

declare let mApp: any;

@Component({
	selector: "case-feedback",
	templateUrl: "./case-feedback.component.html",
	encapsulation: ViewEncapsulation.None,
	styles: [`
	.star {
		font-size: 1.5rem;
		color: #b0c4de;
	}
	.filled {
		color: #34bfa3;
	}
	.bad {
		color: #deb0b0;
	}
	.filled.bad {
		color: #ff1e1e;
	}
	.table-striped tbody tr:nth-of-type(even) {
		background-color: rgba(34, 54, 255, 0.07);
	}
	.table-striped tbody tr:nth-of-type(odd) {
		background-color: rgba(34, 54, 255, 0.07);
	}
	.rating > span:hover:before {
		content: "";
		position: absolute;
	 }
	`]
})

export class CaseFeedbackComponent {
	model: any = {};
	model1: any = {};
	projectObj: any = {};
	currentDate: any;
	@Input() caseFeebackId: any;
	@Output() feedbackSave = new EventEmitter();
	feedbackData = [
		{ feedback_name: "Other", rating_count: "" },
		{ feedback_name: "Customer Support", rating_count: "" },
		{ feedback_name: "EDD", rating_count: "", },
		{ feedback_name: "Product Quality", rating_count: "" },
	];



	constructor(
		private activatedRoute: ActivatedRoute,
		private caseService: CaseService,
		private router: Router,
		private config: NgbRatingConfig,
		private _toastr: ToastrService,
		private _ref: ChangeDetectorRef,
		private date: DatePipe
	) { }
	ngOnInit() {
		this.model["rating"] = [];
		this.model1.feedback = [];
	}
	addfeedback(value) {

		var curDate = new Date();
		this.currentDate = this.date.transform(curDate, 'yyyy-MM-dd HH:mm:ss');

		this.model1.case_id = this.caseFeebackId;
		this.model1.others = "Test FeedBack";

		this.model1.API_KEY = "";
		this.model1.time_stamp = this.currentDate;
		for (let i = 0; i < value.length; i++){
			this.feedbackData[i].rating_count = value[i];
		}
		
		this.model1.feedback.push(this.feedbackData);

		var toastr = new Toastr();
		console.log("save", this.model1);
		this.caseService.getCaseFeedbackPython(this.model1).subscribe(
			data => {
				if (data) {
					//console.log("cehc", data);
					let bodyValue = data["body"];
					let jsonValue = JSON.parse(bodyValue);
					let listValue = jsonValue["remarks"];
					//console.log("cehc", data);
					if (listValue == 'Sucessfully Inserted') {
						this._toastr.success('Feedback added successfully', 'Portal', toastr);
						this.feedbackSave.emit(true);
					}
					else {
						this._toastr.success('Feedback added successfully', 'Portal', toastr);
}

				}
				this._ref.detectChanges();
			});

		// this.model1 = {};
		// this.model.rating = [];
	}


	feedback(id, value, rating) {
		// this.model.feedbackData = [];
		// this.model.feedbackData.push( id,value, rating)
		let feedbackObj = new FeedBack();
		//feedbackObj.item_id = id;
		feedbackObj.feedback_name = value;

		feedbackObj.rating_count = rating;

		this.model.feedback.push(feedbackObj);
		//console.log(this.model.feedback);


		//console.log(requestObject)
		this.model.API_KEY = "";
		this.model.time_stamp = "2019-12-12 23:59:59";
		this.model.case_id = "2019";
		this.model.others = "Test FeedBack";
		console.log(this.model);
	}
}