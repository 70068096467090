import { Injectable } from "@angular/core";
import { Http, Headers, Response, RequestOptions } from "@angular/http";
import { map, catchError, tap, switchMap } from 'rxjs/operators';
import { AppConstantConfig } from '../../config/app.constant';
import { TokenStorage } from './token-storage.service';
import { AuthenticationService } from './authentication.service';
import { AccessData } from './access-data';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { AppSettings } from '../models/app.constants';

@Injectable()
export class AuthService {
    baseString: any;
    constructor(
		private http: Http,
		private tokenStorage: TokenStorage,
		private authenticationService: AuthenticationService
    ) {
    }

	login(email: string, password: string) {
		let _body = JSON.stringify({ loginId: email, password: password });
		// console.log("_body", _body);
		let headers = new Headers({ 'Content-Type': 'application/json' });
		//let headers = new HttpHeaders({ 'Content-Type': 'application/json' })
		let url = AppConstantConfig.API_ENDPOINT_AUTH + 'login';

		return this.http.post(url, _body, { headers }).pipe(
            map((response: Response) => {
				let user = response.json();
				// console.log(user);
				if (user && user.token && user.token != null) {
					localStorage.setItem('currentUser', JSON.stringify(user));
					var accData = {};
					accData["accessToken"] = "access-token-" + user["token"]
					accData["refreshToken"] = "access-token-" + user["token"];
					accData["roles"] = ["ADMIN"];
					//tap(this.authenticationService.saveAccessData.bind(accData));
					this.tokenStorage
						.setAccessToken(accData["accessToken"])
						.setRefreshToken(accData["refreshToken"])
						.setUserRoles(accData["roles"]);
					this.authenticationService.onCredentialUpdated$.next.bind(accData);
					return true;
				}
				return false;
			})
		);
	}

    logout() {
		// remove user from local storage to log user out
		// console.log("log3", 'test');
        localStorage.removeItem('currentUser');
        localStorage.removeItem('tcProjObj');
    }

    hasPermissionAccess(capabilityName: any, permissionType: any) {
        if (localStorage.getItem('permissions')) {
            var decrypt = atob(localStorage.getItem('permissions'));
            var permissionObj = JSON.parse(decrypt);
            var length = permissionObj.length;
            for (var i = 0; i < length; i++) {
                if (permissionObj[i].name == capabilityName) {
                    if (permissionType == 'visible') {
                        var isVisible = permissionObj[i].visible;
                        if (isVisible == 'Y') {
                            return true;
                        }
                        else {
                            return false;
                        }
                    }
                    if (permissionType == 'create') {
                        var isCreate = permissionObj[i].create;
                        if (isCreate == 'Y') {
                            return true;
                        }
                        else {
                            return false;
                        }
                    }
                    if (permissionType == 'read') {
                        var isRead = permissionObj[i].read;
                        if (isRead == 'Y') {
                            return true;
                        }
                        else {
                            return false;
                        }
                    }
                    if (permissionType == 'update') {
                        var isUpdate = permissionObj[i].update;
                        if (isUpdate == 'Y') {
                            return true;
                        }
                        else {
                            return false;
                        }
                    }
                    if (permissionType == 'delete') {
                        var isDelete = permissionObj[i].delete;
                        if (isDelete == 'Y') {
                            return true;
                        }
                        else {
                            return false;
                        }
                    }
                }
            }
        }
    }


	verify() {
        return this.http.get('/api/verify', this.jwt()).pipe(map((response: Response) => response.json()));
	}
	
	public jwt() {
		let currentUser = localStorage.getItem('accessToken');
		currentUser = currentUser.split("access-token-")[1];
        if (currentUser) {
            let headers = new Headers({ 'Access-Control-Allow-Origin': '*' });
            headers.append('Content-Type', 'application/json');
            headers.append('Authorization', 'Bearer ' + currentUser);
            return new RequestOptions({ headers: headers });
        }
	}
	
	dropDownForAcCManagers() {
		let url = AppSettings.API_ENDPOINT + 'auth/getAccountManager';
		return this.http.get(url);
	}
	register(arrayObj) {
		let url = AppSettings.API_ENDPOINT + 'auth/signUp';	
		return this.http.post(url,
			JSON.stringify(arrayObj)).pipe(
				map((response: Response) => response));
	}
	forgotPassword(arrayObj) {
		let url = AppSettings.API_ENDPOINT + 'auth/forgotPassword';	
		return this.http.post(url,
			JSON.stringify(arrayObj)).pipe(
				map((response: Response) => response));
	}

	 resetPassword(arrayObj) {
		let url = AppSettings.API_ENDPOINT + 'auth/resetPassword';	
		return this.http.post(url,
			JSON.stringify(arrayObj)).pipe(
				map((response: Response) => response));
	}


	checkMaill(digest,user,loginId) {
		let url = AppSettings.API_ENDPOINT + 'auth/caseDelivery?caseId='+encodeURIComponent(digest)+'&userId='+encodeURIComponent(user)+'&loginId='+encodeURIComponent(loginId);	
		return this.http.get(url);
	}
}