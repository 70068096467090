import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateTimeFormat'
})
export class DateTimeFormatPipe implements PipeTransform {
	transform(value: any, args?: any): any {
	 let tempDate = value["monthOfYear"] + "/" + value["dayOfMonth"] + "/" + value["year"];
     return tempDate;
   }
}