// tslint:disable-next-line:no-shadowed-variable
import { ConfigModel } from '../core/interfaces/config';

// tslint:disable-next-line:no-shadowed-variable
export class MenuConfig implements ConfigModel {
	public config: any = {};

	constructor() {
		this.config = {
			header: {
				self: {},
				items: [					
					
				]
			},
			aside: {
				self: {},
				items: [					
					{
						title: 'Case Management',
						root: true,
						bullet: 'dot',
						icon: 'flaticon-interface-7',
						submenu: [
							
							{
								title: 'Add Case',
								bullet: 'dot',
								page: '/case-management/addcaseapi'
							},
						
							// {
							// 	title: 'Add Additional Record ',
							// 	bullet: 'dot',
							// 	page: '/case-management/addadditionalrecord'
							// },
							// {
							// 	title: 'File List',
							// 	bullet: 'dot',
							// 	page: '/case-management/casefile'
							// },
							// {
							// 	title: 'Case Result File ',
							// 	bullet: 'dot',
							// 	page: '/case-management/caseresultfile'
							// },
							{
								title: 'Cases ',
								bullet: 'dot',
								page: '/case-management/cases'
							},
							// {
							// 	title: 'Case Feedback ',
							// 	bullet: 'dot',
							// 	page: '/case-management/casefeedback'
							// },
							// {
							// 	title: 'File Upload ',
							// 	bullet: 'dot',
							// 	page: '/case-management/fileupload'
							// }
						]
					}
				]
			}
		};
	}
}
