import { Injectable } from '@angular/core';
import { LayoutConfigService } from '../layout-config.service';
import { ClassInitService } from '../class-init.service';
import { BehaviorSubject } from 'rxjs';
import { HeaderDirective } from '../../directives/header.directive';
import * as objectPath from 'object-path';
import { AppSettings } from '../../../core/models/app.constants';
import { HttpClient, HttpRequest, HttpEvent } from '@angular/common/http';
import { map, catchError, tap, switchMap } from 'rxjs/operators';

@Injectable()
export class HeaderService {
	// class for the header container
	containerClass$: BehaviorSubject<string> = new BehaviorSubject('');
	// class for the header menu close
	headerMenuCloseClass$: BehaviorSubject<string> = new BehaviorSubject('');
	// toggle to display menu on header
	menuHeaderDisplay$: BehaviorSubject<boolean> = new BehaviorSubject(false);
	// minimize offset
	attrMinimizeOffset$: BehaviorSubject<string> = new BehaviorSubject('200');
	// minimize offset on mobile
	attrMinimizeMobileOffset$: BehaviorSubject<string> = new BehaviorSubject('200');

	constructor(
		private layoutConfigService: LayoutConfigService,
		private classInitService: ClassInitService,
		private http: HttpClient,

	) {
		// subscribe to classes update
		this.classInitService.onClassesUpdated$.subscribe(classes => {
			this.headerMenuCloseClass$.next(
				classes.header_menu_close.join(' ')
			);
		});

		this.layoutConfigService.onLayoutConfigUpdated$.subscribe(model => {
			const config = model.config;

			let containerClass = 'm-container m-container--full-height';
			const selfLayout = objectPath.get(config, 'self.layout');
			if (selfLayout === 'boxed' || selfLayout === 'wide') {
				containerClass += ' m-container--responsive m-container--xxl';
			} else {
				containerClass += ' m-container--fluid';
			}
			this.containerClass$.next(containerClass);

			// get menu header display option
			this.menuHeaderDisplay$.next(
				objectPath.get(config, 'menu.header.display')
			);

			this.attrMinimizeOffset$.next(
				objectPath.get(
					config,
					'header.self.fixed.minimize.desktop.offset'
				)
			);

			this.attrMinimizeMobileOffset$.next(
				objectPath.get(
					config,
					'header.self.fixed.minimize.mobile.offset'
				)
			);
		});
	}

	searchCaseDetails(serchTest) {
		let url = AppSettings.API_ENDPOINT + 'app/cases/getCaseDetailsList?pstrCaseName='+serchTest;
		return this.http.get(url);
	}

	getFeedBackCasesHeader() { 
		let url = AppSettings.API_ENDPOINT + 'app/feedback/getDeliveredCasesListForFeedBack';
		return this.http.get(url);
	}

	searchCaseDetailsInitiate(serchTest) {
		let url = AppSettings.API_ENDPOINT + 'app/cases/getCaseDetailsListInitiate?pstrCaseName='+serchTest;
		return this.http.get(url);
	}

	searchCaseDetailsInitiateMore(serchTest,getMore) {
		let url = AppSettings.API_ENDPOINT + 'app/cases/getCaseDetailsListInitiate?pstrCaseName='+serchTest+'&getMore=true';
		return this.http.get(url);
	}
}
