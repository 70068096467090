import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateObjectFormat'
})
export class DateObjectFormatPipe implements PipeTransform {
	transform(value: any, args?: any): any {
		var date = new Date(value);
		console.log("date", value);
		return date;
		
   }
}