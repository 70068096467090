import { Component, OnInit, Input, ChangeDetectionStrategy,ChangeDetectorRef } from '@angular/core';
import { DashboardService } from '../../../core/services/app/dashboard.service';
import { SubheaderService } from '../../../core/services/layout/subheader.service';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { AppConstantConfig } from '../../../config/app.constant';

@Component({
	selector: 'm-subheader',
	templateUrl: './subheader.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubheaderComponent implements OnInit {

	
	model: any = {};
	public modalColumnRef: any;
	selectedbox: { [key: number]: boolean; } = {};
	feedbackProceed: any = false;
	isSelectedCases: any = false;
	selectedCases: any = [];

	constructor(public subheaderService: SubheaderService,
		private modalService: NgbModal,
		private dashboardService: DashboardService,
		private _ref: ChangeDetectorRef,
		private router: Router,
		
		
		
	) { }

	ngOnInit(): void {}

viewFeedBackList(content) {
	
	this.dashboardService.getFeedBackCases().subscribe(
		data => {
			let returnData = data;
			this.model = returnData["dataList"][0];
			// console.log("feedback", this.model);
			this._ref.detectChanges();
		},
		error => {
			console.log("error", error);
		}); 
		let ngbModalOptions: NgbModalOptions = {
			backdrop: 'static',
			keyboard: false,
		
		};
		this.modalColumnRef = this.modalService.open(content, ngbModalOptions);
	}
	
	proceed() {
		
		// this.feedbackProceed = true;
		// let objKeys = Object.keys(this.selectedbox);
		// let trueObj = objKeys.filter(a => (this.selectedbox[a]));
		// console.log("trueObj = " + trueObj);
		// this.modalColumnRef.close();
		// this.router.navigate(['/case-management/cases/feedBack/' + trueObj]);
		// this.selectedbox["key"] ="" ;
		localStorage.removeItem("feedbackcases");
		this.selectedCases = [];
		this.feedbackProceed = true;
		let objKeys = Object.keys(this.selectedbox);
		let trueObj = objKeys.filter(a => (this.selectedbox[a]));
		// console.log("objKeys", objKeys);
		// console.log("this.selectedbox", this.selectedbox);
		for (let index = 0; index < objKeys.length; index++) {
			if (this.selectedbox[objKeys[index]]) {
				var tempObj = {};
				tempObj["encryptedId"] = objKeys[index];
				this.selectedCases.push(tempObj);
			}
		}
		// console.log("this.selectedCases", this.selectedCases);
		this.isSelectedCases = false;
		if (this.selectedCases.length > 0) {
			this.isSelectedCases = true;
		}
		localStorage.setItem("feedbackcases",JSON.stringify(this.selectedCases));
		// console.log(" this.selectedCases ", this.selectedCases)
		this.selectedbox["key"] = "";
		this.selectedbox = {};
		this.modalColumnRef.close();
		this.router.navigate(['/case-management/commonfeedback/']);
		
	}

	cancel() {
		this.modalColumnRef.close();
	}
	onCheckedChange(i: number, $event) {
		
		this.selectedbox[i] = $event;
		//console.log("slave $event = " + $event);
		//console.log("this.selectedSlaves ", this.selectedbox);
	}
	openoldPortal() {
		window.open(AppConstantConfig.API_ENDPOINT_OLD_PORTAL);
	}
	cancelUpdateColumnModal(editCol: any) {
		this.modalColumnRef.close();
	}
}