import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'htmlFormat'
})
export class HtmlFormatPipe implements PipeTransform {
	transform(value: any, args?: any): any {
		let tempDate = decodeURIComponent(value);
		console.log("value - ", value);
     	return tempDate;
   }
}