import { Injectable, ErrorHandler } from "@angular/core";
import { Http, Headers, Response } from '@angular/http';
import { AuthenticationService } from '../../auth/authentication.service';
import { AppSettings } from '../../../core/models/app.constants';
import { Observable, Subject } from 'rxjs';
import { map, catchError, tap, switchMap } from 'rxjs/operators';
import { Router, NavigationStart } from '@angular/router';
import { HttpClient, HttpRequest, HttpEvent } from '@angular/common/http';

@Injectable()
export class CaseService {
	private subject = new Subject<any>();
	constructor(
		private http: HttpClient,
		private authenticationService: AuthenticationService,
		private router: Router,
		private localhttp: Http
	) {
		router.events.subscribe(event => {
			if (event instanceof NavigationStart) {
				this.subject.next();
			}
		});
	}

	saveCase(projectObj: any) {
		let url = AppSettings.API_ENDPOINT + 'app/cases/saveCase.json?pcd=tz';
		//var ss = this.authenticationService.jwt();
		//ss.body = JSON.parse(JSON.stringify(projectObj));
		console.log("Case Add Details::", projectObj);
		return this.http.post(url,
			JSON.stringify(projectObj)).pipe(
				map((response: Response) => response));

	}
	addCase() {
		let url = AppSettings.API_ENDPOINT + 'app/cases/addCase';
		// return this.http.get(url, this.authenticationService.jwt()).pipe(
		// 	map((response: Response) => response.json()));
		return this.http.get(url);
	}
	saveContact(projectObj: any) {
		let url = AppSettings.API_ENDPOINT + 'app/contact/saveContacts.json?pcd=tz';
		return this.http.post(url,
			JSON.stringify(projectObj)).pipe(
				map((response: Response) => response));
	}
	addContact(entity, entityId) {
		let url = AppSettings.API_ENDPOINT + 'app/contact/addContact?pcd=tz&entity=' + entity + '&entityId=' + entityId;
		return this.http.get(url);
	}
	getContactDetailsForUser(userId) {
		let url = AppSettings.API_ENDPOINT + 'app/contact/getContactDetailsForAccountUser?pcd=tz&userId=' + userId;
		return this.http.get(url);
	}

	saveCaseQuery(projectObj) {
		let url = AppSettings.API_ENDPOINT + 'app/queries/saveCaseQuery';
		return this.http.post(url,
			projectObj).pipe(
				map((response: Response) => response));
	}

	dropDownForClientType() {
		let url = AppSettings.API_ENDPOINT + 'app/utils/getClientType';
		return this.http.get(url);
	}
	dropDownForlabels() {
		let url = AppSettings.API_ENDPOINT + 'app/utils/getAppLabels';
		return this.http.get(url);
	}
	dropDownForAccounts() {
		let url = AppSettings.API_ENDPOINT + 'app/utils/getAccount?pcd=tz';
		return this.http.get(url);
	}
	dropDownForClients(accountId) {
		let url = AppSettings.API_ENDPOINT + 'app/utils/getClients?pcd=tz&accountId=' + accountId;
		return this.http.get(url);
	}
	dropDownForCaseType() {
		let url = AppSettings.API_ENDPOINT + 'app/utils/getCaseType';
		return this.http.get(url);
	}
	dropDownForCaseSubType(name) {
		let url = AppSettings.API_ENDPOINT + 'app/utils/getCaseSubTypeByName?pcd=tz&name=' + name;
		return this.http.get(url);
	}
	screenFilterVO() {
		let url = AppSettings.API_ENDPOINT + 'app/cases/getScreenFilter';
		return this.http.get(url);
	}
	listCases(queryObj: any) {
		let url = AppSettings.API_ENDPOINT + 'app/cases/getCaseList.json?pcd=tz';
		return this.http.post(url, JSON.stringify(queryObj));
	}
	getPagger(paggerObj: any, pssortOrder: any, pssortByField: any) {
		let pageObj = JSON.stringify(paggerObj);
		let url = AppSettings.API_ENDPOINT + 'app/cases/getPaggination/' + pageObj + '/' + pssortOrder + '/' + pssortByField;
		console.log("API Test", url);
		return this.http.get(url);
	}
	dropDownForCommunicationType() {
		let url = AppSettings.API_ENDPOINT + 'app/utils/getCommunicationType';
		return this.http.get(url);
	}

	dropDownForCommnSubType(commnTypeId) {
		let url = AppSettings.API_ENDPOINT + 'app/utils/getCommunicationSubType?pcd=tz&commnTypeId=' + commnTypeId;
		return this.http.get(url);
	}

	finishCaseFileUpload(caseId) {
		let url = AppSettings.API_ENDPOINT + 'app/cases/finishCaseFileUpload?pcd=tz&caseId=' + caseId;
		return this.http.get(url);
	}
	dropDownForContacts(entityId, entity) {
		let url = AppSettings.API_ENDPOINT + 'app/utils/caseContacts?pcd=tz&entityId=' + entityId + '&entity=' + entity;
		return this.http.get(url);
	}
	listCaseServices(caseId) {
		let url = AppSettings.API_ENDPOINT + 'app/cases/listCaseServiceReq?pcd=tz&caseId=' + caseId;
		return this.http.get(url);
	}
	addCaseService(caseId) {
		let url = AppSettings.API_ENDPOINT + 'app/cases/addCaseServiceReq?pcd=tz&caseId=' + caseId;
		return this.http.get(url);
	}
	editCaseServiceReqDetail(caseServiceReqDetailId, parent) {
		let url = AppSettings.API_ENDPOINT + 'app/cases/editCaseServiceReqDetail?pcd=tz&caseServiceReqDetailId=' + caseServiceReqDetailId + '&isParent=' + parent;
		return this.http.get(url);
	}
	getSeconds() {
		let url = AppSettings.API_ENDPOINT + 'app/utils/getSeconds?pcd=tz';
		return this.http.get(url);
	}


	// getAdditionalRecordDetails(caseId) {
	// 	let url = AppSettings.API_ENDPOINT + 'app/cases/getAdditionalRecDetails?pcd=tz&caseId=' + caseId;
	// 	return this.http.get(url);
	// }

	saveAdditionalRecords(projectObj: any) {
		let url = AppSettings.API_ENDPOINT + 'app/cases/caseAdditionalFiles?pcd=tz';
		return this.http.post(url,
			JSON.stringify(projectObj)).pipe(
				map((response: Response) => response));
	}
	saveCaseService(projectObj: any) {
		let url = AppSettings.API_ENDPOINT + 'app/cases/saveCaseServiceReq.json?pcd=tz';
		console.log("Case Add Details::", projectObj);
		return this.http.post(url,
			JSON.stringify(projectObj)).pipe(
				map((response: Response) => response));

	}
	
	listAnnouncements() {
		let url = AppSettings.API_ENDPOINT + 'app/admin/announcements';
		return this.http.get(url);
	}
	

	editCase(caseId: any) {
		let url = AppSettings.API_ENDPOINT + 'app/cases/caseDetails?pcd=tz&caseId=' + caseId;
		return this.http.get(url);
	}



	upload(file: File) {
		const formdata: FormData = new FormData();
		formdata.append('file', file);
		let url = AppSettings.API_ENDPOINT + 'app/queries/upload.json?pcd=tz&file' + file;
		return this.http.get(url);
		// return this.http.post(url,
		// 	formdata).pipe(
		// 		map((response: Response) => response));
		// const req = new HttpRequest('POST', AppSettings.API_ENDPOINT + 'app/queries/upload', formdata, {
		// 	reportProgress: true,
		// 	responseType: 'text'
		// }
		// );
		//return this.http.request(req);
		// let url = AppSettings.API_ENDPOINT + 'app/queries/upload';
		// return this.http.post(url,
		// 	projectObj).pipe(
		// 		map((response: Response) => response));
	}

	dropDownForUsers() {
		let url = AppSettings.API_ENDPOINT + 'app/utils/getUsers';
		return this.http.get(url);
	}
	dropDownForCases() {
		let url = AppSettings.API_ENDPOINT + 'app/utils/getCases';
		return this.http.get(url);
	}


	getCompletedCaseListByEncId(queryObj: any) {
		let url = AppSettings.API_ENDPOINT + 'app/caseResultFiles/getCompletedCaseFileListById';
		return this.http.post(url, JSON.stringify(queryObj));
	}

	getUploadCaseListByEncId(caseId: any) {
		let url = AppSettings.API_ENDPOINT + 'app/caseFiles/getUploadCaseFileListById?caseId=' + caseId;
		return this.http.get(url);
	}
	
	getAccessTokenForCaseFile(caseid, filetype) {
		let url = AppSettings.API_ENDPOINT + 'app/files/getFileUploadAccessTocken?pcd=tz&pstrCaseIds=' + caseid + '&pstrFileType=' + filetype;
		return this.http.get(url);
	}
	editCaseServiceReq(caseServiceReqId, addtionalRecId) {
		let url = AppSettings.API_ENDPOINT + 'app/cases/editCaseServiceReq?pcd=tz&caseServiceReqId=' + caseServiceReqId;
		return this.http.get(url);
	}
	getcaseFilesPagger(paggerObj: any, pssortOrder: any, pssortByField: any) {
		let pageObj = JSON.stringify(paggerObj);
		let url = AppSettings.API_ENDPOINT + 'app/caseFiles/getPaggination/' + pageObj + '/' + pssortOrder + '/' + pssortByField;
		console.log("API Test", url);
		return this.http.get(url);
	}
	getCaseFileList(queryObj: any) {
		let url = AppSettings.API_ENDPOINT + 'app/caseFiles/getCaseFileList.json?pcd=tz';
		return this.http.post(url, JSON.stringify(queryObj));
	}

	updateCaseFileIdinCahngeFileHistory(addRecId) {
		let url = AppSettings.API_ENDPOINT + 'app/cases/updateCaseFileIdinCahngeFileHistory?pcd=tz&addRecId=' + addRecId;
		return this.http.get(url);
	}
	getcaseResultFilesPagger(paggerObj: any, pssortOrder: any, pssortByField: any) {
		let pageObj = JSON.stringify(paggerObj);
		let url = AppSettings.API_ENDPOINT + 'app/caseResultFiles/getPaggination/' + pageObj + '/' + pssortOrder + '/' + pssortByField;
		console.log("API Test", url);
		return this.http.get(url);
	}

	getFeedBackQuestions(psCaseId) {
		let url = AppSettings.API_ENDPOINT + 'app/feedback/getFeedBackQns?encryptedId=' + psCaseId;
		return this.http.get(url);
	}

	saveFeedbackQueestions(feebackObj: any) {
		let url = AppSettings.API_ENDPOINT + 'app/feedback/saveFeedBackQns';
		return this.http.post(url,
			JSON.stringify(feebackObj)).pipe(
				map((response: Response) => response));
	}

	getCaseInfo(caseEncId) {
		//caseEncId = encodeURIComponent(caseEncId);
		let url = AppSettings.API_ENDPOINT + 'app/cases/caseDetailsInfobyId?encryptcaseId=' + caseEncId;
		return this.http.get(url);
	}

	getLoginDetails() {
		var jwtDecode = require('jwt-decode');
		let loginToken = localStorage.getItem("accessToken");
		loginToken = loginToken.split("access-token-")[1];
		let value = jwtDecode(loginToken);
		return value;
	}
	getCaseAdditionalFilesDetails(caseId: any) {
		let url = AppSettings.API_ENDPOINT + 'app/cases/caseAdditionalRecordsDetails?pcd=tz&caseId=' + caseId;
		return this.http.get(url);
	}
	casefilescreenFilterVO() {
		let url = AppSettings.API_ENDPOINT + 'app/caseFiles/getScreenFilter';
		return this.http.get(url);
	}
	getCaseType() {
		let url = AppSettings.API_ENDPOINT + 'app/utils/getCaseType';
		return this.http.get(url);
	}
	getClientStatus() {
		let url = AppSettings.API_ENDPOINT + 'app/utils/getClientStatus';
		return this.http.get(url);
	}
	caseresultfilescreenFilterVO() {
		let url = AppSettings.API_ENDPOINT + 'app/caseResultFiles/getScreenFilter';
		return this.http.get(url);
	}
	getAccountManager() {
		let url = AppSettings.API_ENDPOINT + 'auth/getAccountManager';
		return this.http.get(url);
	}
	triggerMail(caseId, pstrFileType, pstrRecordsType, pstrAdditionalService) {
		let url = AppSettings.API_ENDPOINT + 'app/cases/CaseditionalDetailsMail?pcd=tz&caseId=' + caseId + '&pstrFileType=' + pstrFileType + '&pstrRecordsType=' + pstrRecordsType + '&pstrAdditionalService=' + pstrAdditionalService;
		return this.http.get(url);
	}
	
	finishDownload(caseId) {
		let url = AppSettings.API_ENDPOINT + 'app/caseFiles/filesDownloadConfirm?pcd=tz&caseId=' + caseId + '&isdownloaded=Y';
		return this.http.get(url);
	}
	getCaseResultpage(caseId, userId, page, pageCount) {
		let url = AppSettings.API_ENDPOINT + 'auth/caseResultFiles?pcd=tz&caseId=' + caseId + '&userId=' + userId + '&intPageCnt=' + page + '&intRecordsPerPage=' + pageCount;
		return this.http.get(url);
	}
	sendEmailForQuery(projectObj) {
		let url = AppSettings.API_ENDPOINT + 'app/queries/sendCaseQueryEmail';
		return this.http.post(url,
			projectObj).pipe(
				map((response: Response) => response));
			
	}
	saveAttachForQuery(projectObj) {
		let url = AppSettings.API_ENDPOINT + 'app/queries/saveAttachForQuery';
		return this.http.post(url,
			projectObj).pipe(
				map((response: Response) => response));
			
	}
	sendEmailForQueryResponse(projectObj) {
		let url = AppSettings.API_ENDPOINT + 'app/queries/sendCaseQueryResponseEmail';
		return this.http.post(url,
			projectObj).pipe(
				map((response: Response) => response));
			
	}
	getCaseByPython(projectObj) {
		localStorage.setItem("AWS", 'AWS');
		let url = 'https://e05jo394a5.execute-api.ap-south-1.amazonaws.com/caseList';
		//let url = 'https://zc5ih82d43.execute-api.ap-south-1.amazonaws.com/get_case_list/case-list';
		return this.http.post(url,
			projectObj).pipe(
				map((response: Response) => response));
	}
	getCaseServicesByPython(projectObj) {
		localStorage.setItem("AWS", 'AWS');
		let url = 'https://8tqx5wq7ui.execute-api.ap-south-1.amazonaws.com/serviceList';
		return this.http.post(url,
			projectObj).pipe(
				map((response: Response) => response));
	}
	getCaseAddRecByPython(projectObj) {
		localStorage.setItem("AWS", 'AWS');
		let url = 'https://1odh681c07.execute-api.ap-south-1.amazonaws.com/generateAdditionalRecordList';
		//let url = 'https://cmv7ty8ouf.execute-api.ap-south-1.amazonaws.com/saveadditionalRec';
		return this.http.post(url,
			projectObj).pipe(
				map((response: Response) => response));
	}
	getCaseFilesByPython(projectObj) {
		localStorage.setItem("AWS", 'AWS');
		let url = 'https://g425c2mq2h.execute-api.ap-south-1.amazonaws.com/CaseFile';
		return this.http.post(url,
			projectObj).pipe(
				map((response: Response) => response));
	}
	getCaseResFilesByPython(projectObj) {
		localStorage.setItem("AWS", 'AWS');
		let url = 'https://dy9vmvc2x2.execute-api.ap-south-1.amazonaws.com/CaseResultFile';
		return this.http.post(url,
			projectObj).pipe(
				map((response: Response) => response));
	}
	getCaseDetRecByPython(projectObj) {
		localStorage.setItem("AWS", 'AWS');
		let url = 'https://3rbgiz9gwk.execute-api.ap-south-1.amazonaws.com/getCaseDetails';
		return this.http.post(url,
			projectObj).pipe(
				map((response: Response) => response));
	}
	getCaseSavePython(projectObj) {
		localStorage.setItem("AWS", 'AWS');
		let url = 'https://00l95zkis4.execute-api.ap-south-1.amazonaws.com/SaveCase';
		return this.http.post(url,
			projectObj).pipe(
				map((response: Response) => response));
	}
	getCaseServiceByPython(projectObj) {
		localStorage.setItem("AWS", 'AWS');
		let url = 'https://73x467sucd.execute-api.ap-south-1.amazonaws.com/saveService';
		return this.http.post(url,
			projectObj).pipe(
				map((response: Response) => response));
	}
	getCaseFeedbackPython(projectObj) {
		localStorage.setItem("AWS", 'AWS');
		let url = 'https://g610ztqzea.execute-api.ap-south-1.amazonaws.com/SaveFeedBack';
		return this.http.post(url,
			projectObj).pipe(
				map((response: Response) => response));
	}
	loadDropDown(projectObj) {
		localStorage.setItem("AWS", 'AWS');
		let url = 'https://3zje9z5nb2.execute-api.ap-south-1.amazonaws.com/loadingDropdowns';
		return this.http.post(url,
			projectObj).pipe(
				map((response: Response) => response));
	}
	
	getAdditionalRecordDetails(projectObj) {
		localStorage.setItem("AWS", 'AWS');
		let url = 'https://5zxq2tjcqd.execute-api.ap-south-1.amazonaws.com/getAddRecDetailForCase';
		return this.http.post(url,
			projectObj).pipe(
				map((response: Response) => response));
	} 
	saveAdditionalRecordDetails(projectObj) {
		localStorage.setItem("AWS", 'AWS');
		let url = 'https://cmv7ty8ouf.execute-api.ap-south-1.amazonaws.com/saveadditionalRec';
		return this.http.post(url,
			projectObj).pipe(
				map((response: Response) => response));
	}
	updateCaseFileIdinCahngeFileHistorypython(projectObj) {
		localStorage.setItem("AWS", 'AWS');
		let url = 'https://nrfgbpye4f.execute-api.ap-south-1.amazonaws.com/updateCaseFileIDinAddRec';
		return this.http.post(url,
			projectObj).pipe(
				map((response: Response) => response));
	}
	
	getToken(projectObj) {
		localStorage.setItem("AWS", 'AWS');
		let url = 'https://lu15sv6isk.execute-api.ap-south-1.amazonaws.com/getToken';
		return this.http.post(url,
			projectObj).pipe(
				map((response: Response) => response));
	}
	
	updateCaseFields(projectObj) {
		localStorage.setItem("AWS", 'AWS');
		let url = 'https://i9xh7m08m1.execute-api.ap-south-1.amazonaws.com/updateCaseFields';
		return this.http.post(url,
			projectObj).pipe(
				map((response: Response) => response));
	}
}