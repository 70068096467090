export class Toastr {
    timeOut: any
    closeButton: boolean
    positionClass: string
    enableHtml: boolean

    constructor() {
        this.timeOut = 6000;
        this.closeButton = true;
        this.positionClass = 'toast-top-right';
        this.enableHtml = true;
    }
}