import { Injectable,Input,EventEmitter } from '@angular/core';
import * as S3 from 'aws-sdk/clients/s3';
import { FileUpload } from '../../services/app/file-upload';
import { CaseService } from '../../../core/services/app/case.service';
import * as AWS from 'aws-sdk';
import { ObservalbeService } from '../observable.service';
import { Observable } from 'rxjs';
import { HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';
import { AnyAaaaRecord } from 'dns';
declare let mApp: any;
@Injectable()
export class UploadFileService {
	@Input()
	public myCallback: Function; 

	caseid : any;
	filetype : any;
	additionalRecId: any;
	fileUploaded: boolean;

	BUCKET = 'aalamdev';
	
	public copyEvent: EventEmitter<string>;

	customIndex: any;
	private s3: AWS.S3;
	public progress: EventEmitter<AWS.S3.ManagedUpload.Progress> = new EventEmitter<AWS.S3.ManagedUpload.Progress>();
  

	constructor(private caseService: CaseService,private http: HttpClient
		) {
		this.copyEvent = new EventEmitter();
	}

uploadfile(file, paramForCaseFile) {
	this.caseid = paramForCaseFile.caseid.toString();
	this.filetype = paramForCaseFile.filetype.toString();
	var fileName = file["name"];
	AWS.config.update({
			accessKeyId: "ASIA2VJW75UEPP7DJHGV",
			secretAccessKey: "JTC1RpCTvFvxAEoXA4qZDjYzXSyBRS/9OXig0ZVA",
			sessionToken: "FQoGZXIvYXdzELb//////////wEaDLaQNtfTvpIQ96iT0SKQAvvclHXiSpIs4Ke90zKKQebbv5kSnTvPvvvpcjg1dvTH0MLkcgL0z9tCrz6LF1F2YOEZwVkJKpceFl6d50xbOC2XmXZb5ZqP6TT5xC+IXIB5KFgiptewITp+pFMyTh8yuiXRN5PGlh6LOdsB9ZSDtovbTIdPKz7IJV8BXZ7E5kPnFH3Qanuz0jm5UVAvu6LZapNEauKL9A2iC35ZsoquVXWOKWfeKs+GMvGw1T6d8KMeyvfNaQGTy5cCuhhKxGym58SdeSSr/46wseJ71nfbutsidSqlqE5LNkFz5oEziX8WkjVWQhRAVXx5MLW/xpM8NyhGbvSKpbMS5zDSuXbBSGk9d28GrYtFe/EBtvwNinrtKIHc1OMF", 
	    	region: "ap-south-1",								
	});
						var d = new Date();
						var localTime = d.getTime();
						var localOffset = d.getTimezoneOffset() * 60000;
						var utc = localTime + localOffset;
						var offset = 0; 
						var utctime = utc + (3600000 * offset);
						this.caseid = "29065"
						const params = {
							Bucket: "aallam-bucket-dev",
							Key: "DownloadFiles/" +this.caseid +"/"+utctime.toString()+"/"+ fileName,
							Body: file,
							// ACL: 'public-read',
							  Metadata: {
								  "file-typeint": "1",
								  "case-id": "29065",
								  "client-id": "5068",
								  "account-id": "",
								  "is-zipped": "N",
								  "created-by": "",
								  "modified-by": "",
								  "is-latest": "Y",
								  "version": "1",
								  "file-type": ""
							  }
						  };
						var s3 = new AWS.S3();					
						// s3.putObject(params, function (err, res) {
						// 	if (err) {
						// 		console.log("Error File Uploading data", err);
						// 	} else {
						// 		console.log("Files Uploaded Successdully", res);
						// 		this.copyEvent.emit("Upload");
						// 	}
						// });

						// s3.putObject(params).promise()
						// .then(data => {							
						// 	console.log("Files Uploaded Successful", data);	
						// 	this.copyEvent.emit("Upload");
						// })
						// .catch(error => {						
						// 	console.log("Error File Uploading data", error);
						// 	})
						
						s3.upload(params).on('httpUploadProgress', function(params) {
								console.log("Uploaded :: " + (params.loaded * 100) / params.total) + '%';
								
						}).send(function(err, data) {
							alert("File uploaded successfully.");
						});

					
	 	
}
	
 /**
   * @desc AWS.S3
   */
  public onManagedUpload(file: File,paramForCaseFile:any,AWS_CONFIG:any, fileIndex: any): Promise<AWS.S3.ManagedUpload.SendData>
  {
	this.caseid = paramForCaseFile.caseid.toString();
	this.filetype = paramForCaseFile.filetype.toString();
	var fileName = file["name"];
	var additionalrec = "";
	var production = "";
	var file_description = "";
	if(paramForCaseFile.additionalrecid!=undefined){  
		if (paramForCaseFile.additionalrecid.toString().length > 0) {
			additionalrec = paramForCaseFile.additionalrecid.toString();
		} 
	}	
	if (paramForCaseFile.productionId != undefined) {
		  if (paramForCaseFile.productionId.toString().length > 0) {
			  production = paramForCaseFile.productionId.toString();
		  }
	}	
	if (paramForCaseFile.file_description != undefined) {
		  if (paramForCaseFile.file_description.toString().length > 0) {
			file_description = paramForCaseFile.file_description.toString();
		  }
	}	  
	  
	// AWS.config.update({
	// 	accessKeyId: "ASIATZIKA7KOCRYO3APH",
	// 	secretAccessKey: "yrXCKzV+5gQkYqSa3HK+3uBbUcxx154WHBw+E/yl",
	// 	sessionToken: "FQoGZXIvYXdzED4aDLkjy39278PwidXdjCKOAhauNuS1b7whc36cB7qioCrPrfUiLbHrOyNr8oyWT1/b2TeFUE55yX2zHLyOfFspn14z1fF5awpugOH/+LW6mF3x5A6R3DtyNAefXUzPBwTYH5NrTCRYVdahX1w3FABrwCiHedWBG5wMtFRLHqwcKRFr9odEqwbSEzK444qRDZ7gZsaspFlOtMqT941mQL/0jgze6ZlcFy5y23MRvJqFUJcmws5sdGnOneKpOM0/anU27zkjjr14+MEyxSeMN0FewjCb6OywEc4BiHuUbCDEIE9S18FQqm3xqC8E65P2cVvPnhgJktV2Vs3OyA8oAyt9IpvI/eYl2rKn7/Hxb34M9z0FewwrfJkv9Id7QnqdSCir7ujgBQ==", 
	// 	region: "ap-south-1",								
	// });
	
	AWS.config.update({
		accessKeyId: AWS_CONFIG["accessKeyId"],
		secretAccessKey: AWS_CONFIG["secretAccessKey"],
		sessionToken: AWS_CONFIG["sessionToken"], 
		region: AWS_CONFIG["region"],								
	});
	var d = new Date();
	var localTime = d.getTime();
	var localOffset = d.getTimezoneOffset() * 60000;
	var utc = localTime + localOffset;
	var offset = 0; 
	var utctime = utc + (3600000 * offset);
	var s3 = new AWS.S3();					
	let params1: AWS.S3.Types.PutObjectRequest = {
		Bucket: AWS_CONFIG["bucket"],
		Key: "DownloadFiles/" +this.caseid +"/"+utctime.toString()+"/"+ fileName,
		Body: file,
		// ACL: 'public-read',
		Metadata: {
			"file-typeint": "1",
			"case-id": paramForCaseFile.caseid.toString(),
			"client-id": paramForCaseFile.clientid.toString(),
			"account-id": paramForCaseFile.accountid.toString(),
			"is-zipped": "N",
			"created-by": paramForCaseFile.createdby.toString(),
			"modified-by": paramForCaseFile.modifiedby.toString(),
			"is-latest": "Y",
			"version": "1",
			"file-type": paramForCaseFile.filetype.toString(),
			"additional-rec-id":additionalrec, 
			"production-id":production, 
			"file-description":file_description
		}
	  };
	  let options: AWS.S3.ManagedUpload.ManagedUploadOptions = {
		params: params1,
		//partSize: 64*1024*1024,
	  };
	  this.customIndex = fileIndex;
	  let handler: AWS.S3.ManagedUpload = new AWS.S3.ManagedUpload(options);
	  //handler["customIndex"] = "12";
	  handler.on('httpUploadProgress', this._httpUploadProgress.bind(this));  
	  handler.send(this._send.bind(this));
	  var promise =  handler.promise();
	//   promise.then(function (data) {
	// 		alert("File Uploaded Succefully!!!1");
	// 		return handler.promise(); 
	// 		}, function(err) {			
	// 		return handler.promise(); 
	// 	});
	  //console.log("handler.promise()", handler.promise());  
	  return  handler.promise(); 
  }

/**
   * @desc progress
   */
  private _httpUploadProgress(progress: AWS.S3.ManagedUpload.Progress): void
  {
	  progress["customIndex"] = this.customIndex;
	  this.progress.emit(progress);
  }

  /**
   * @desc send
   */
  private _send(err: AWS.AWSError, data: AWS.S3.ManagedUpload.SendData): void
  {
	  console.log('send()', err, data);	 
  }
	
public uploadAdditionalCasefile(file, paramForCaseFile,length,i) {
	this.caseid = paramForCaseFile.caseid.toString();
	this.filetype = paramForCaseFile.filetype.toString();
	this.additionalRecId = paramForCaseFile.additionalrecid.toString();
	
	var fileName = file["name"];
    
	this.caseService.getAccessTokenForCaseFile(this.caseid, this.filetype).subscribe(
		data => {			
			if (data) {	
				let returnData = data;				
				if (returnData["isData"]) {
					let objModel = returnData["data"];
					let obj = JSON.parse(objModel)
					var obj1 = JSON.parse(obj.body);	
					let AWSREGION = returnData["dataList"][0];	
							
					AWS.config.update({						 
						   accessKeyId: obj1.key,
						   secretAccessKey: obj1.SecretAccessKey,
						   sessionToken: obj1.SessionToken, 
						   region: AWSREGION.awsRegion.toString(),							   
						   useAccelerateEndpoint: true,
					  });
					var d = new Date();
					var localTime = d.getTime();
					var localOffset = d.getTimezoneOffset() * 60000;
					var utc = localTime + localOffset;
					var offset = 0; 
					var utctime = utc + (3600000 * offset);
					
					const params = {
						Bucket: AWSREGION.awsBucket.toString(),
						Key: "DownloadFiles/" +this.caseid +"/"+utctime.toString()+"/"+ fileName,
						Body: file,
						// ACL: 'public-read',
						  Metadata: {
							  "file-typeint": "1",
							  "case-id": paramForCaseFile.caseid.toString(),
							  "additional-rec-id" : paramForCaseFile.additionalrecid.toString(),
							  "client-id": paramForCaseFile.clientid.toString(),
							  "account-id": paramForCaseFile.accountid.toString(),
							  "is-zipped": "N",
							  "created-by": paramForCaseFile.createdby.toString(),
							  "modified-by": paramForCaseFile.modifiedby.toString(),
							  "is-latest": "Y",
							  "version": "1",
							  "file-type": paramForCaseFile.filetype.toString()
						  }
					   };
					   var s3 = new AWS.S3();				   
					//    s3.putObject(params, function (err, res) {
					// 	   if (err) {
					// 		   console.log("Error File Uploading data", err);
					// 	   } else {
					// 		   //alert("File Uploaded Successful");
					// 		   console.log("Files Uploaded Successful",res);	
					// 	   }						   
					//    });
					
					   s3.putObject(params).promise()
					   .then(data => {
						   // this is the same as the data callback parameter
						  // alert("Files Uploaded Successful");
						   console.log("Files Uploaded Successful", data);
						   
						   if (i == length-1) {
							  
							   this.updateChangeFileHistory(paramForCaseFile["additionalrecid"]);	
							   
							}
					   })
					   .catch(error => {
						   // handle your error
						   console.log("Error File Uploading data", error);
					   })
					  
					
				} else {
					console.log("Not Valid User!");
				}
								   
			} else {	
				console.log("Data Failed to save!");
			}
		},
		error => {
			console.log(error);
		});
}

fileUploadedChnageHistory(additionalrecid) {
	this.caseService.updateCaseFileIdinCahngeFileHistory(additionalrecid).subscribe(
	   data => {
		   let returnData = data;
		   console.log("dataContact", returnData);									
	   },
	   error => {
		   console.log("error", error);
	   });	
  }	

downloadFilesFromS3(fileLocation,fileName,fileCaseTypes,fileCaseId) {
		var theObjectLocation = fileLocation +"/" + fileName;
					
				let tokenValue = {
							"API_KEY":"",
							"time_stamp":"2019-12-12 23:59:59",	
							"case_id":fileCaseId,
							"login_id": "test_abc@aalamsoft.com",
							"additional_record_id":""
							
						}
						let paramForCaseFile = {};
						let AWS_CONFIG = {};
						this.caseService.getToken(tokenValue).subscribe(
							data => {
								if (data) {
									console.log("tokrn", data);
									console.log("dropdown", data);
									debugger;
									let bodyValue = data["body"];
									let jsonValue = JSON.parse(bodyValue);
									
						
									paramForCaseFile["filetypeint"] = "1";
									paramForCaseFile["caseid"] = jsonValue["case_id"];
									paramForCaseFile["additionalrecid"] = "";
									paramForCaseFile["clientid"] = jsonValue["client_id"];
									paramForCaseFile["accountid"] = jsonValue["account_id"];
									paramForCaseFile["iszipped"] = "N";
									paramForCaseFile["createdby"] = jsonValue["client_id"];
									paramForCaseFile["modifiedby"] = jsonValue["client_id"];
									paramForCaseFile["islatest"] = "Y";
									paramForCaseFile["version"] = "1";
									paramForCaseFile["filetype"] = "case_files";
									// console.log("CASEID", paramForCaseFile);
									this.filetype = "case_files";
									paramForCaseFile["filetype"] = "case_files";
									
									//AWS_CONFIG["accessKeyId"] = "ASIA2VJW75UEPP7DJHGV";
									//AWS_CONFIG["secretAccessKey"] = "JTC1RpCTvFvxAEoXA4qZDjYzXSyBRS/9OXig0ZVA";
									//AWS_CONFIG["sessionToken"] = "FQoGZXIvYXdzELb//////////wEaDLaQNtfTvpIQ96iT0SKQAvvclHXiSpIs4Ke90zKKQebbv5kSnTvPvvvpcjg1dvTH0MLkcgL0z9tCrz6LF1F2YOEZwVkJKpceFl6d50xbOC2XmXZb5ZqP6TT5xC+IXIB5KFgiptewITp+pFMyTh8yuiXRN5PGlh6LOdsB9ZSDtovbTIdPKz7IJV8BXZ7E5kPnFH3Qanuz0jm5UVAvu6LZapNEauKL9A2iC35ZsoquVXWOKWfeKs+GMvGw1T6d8KMeyvfNaQGTy5cCuhhKxGym58SdeSSr/46wseJ71nfbutsidSqlqE5LNkFz5oEziX8WkjVWQhRAVXx5MLW/xpM8NyhGbvSKpbMS5zDSuXbBSGk9d28GrYtFe/EBtvwNinrtKIHc1OMF";
				
									AWS_CONFIG["region"] = jsonValue["region"];
									AWS_CONFIG["bucket"] = jsonValue["Bucket"];
				
									if (undefined != data["body"] && null != data["body"]) {
										let bodyVal = JSON.parse(data["body"])
										if (undefined != bodyVal["Keys"] && null != bodyVal["Keys"]) {
											if (undefined != bodyVal["Keys"]["body"] && null != bodyVal["Keys"]["body"] && "" != bodyVal["Keys"]["body"]) {
												if (undefined != bodyVal["Keys"]["body"] && null != bodyVal["Keys"]["body"] && "" != bodyVal["Keys"]["body"]) {
													let tokenVal = JSON.parse(bodyVal["Keys"]["body"])
													if (undefined != tokenVal["key"] && null != tokenVal["key"] && "" != tokenVal["key"]) {
														AWS_CONFIG["accessKeyId"] = tokenVal["key"];
													}
													if (undefined != tokenVal["SecretAccessKey"] && null != tokenVal["SecretAccessKey"] && "" != tokenVal["SecretAccessKey"]) {
														AWS_CONFIG["secretAccessKey"] = tokenVal["SecretAccessKey"]
													}
													if (undefined != tokenVal["SessionToken"] && null != tokenVal["SessionToken"] && "" != tokenVal["SessionToken"]) {
														AWS_CONFIG["sessionToken"] = tokenVal["SessionToken"]
													}
												}
											}
										}
									}
									
									
									
										
								}
								AWS.config.update({						 
									accessKeyId: AWS_CONFIG["accessKeyId"],
									secretAccessKey: AWS_CONFIG["secretAccessKey"],
									sessionToken: AWS_CONFIG["sessionToken"], 
									region: AWS_CONFIG["region"],							   
									useAccelerateEndpoint: true,
							   });
								var theObjectLocation = fileLocation + "/" + fileName;
								var s3 = new AWS.S3();
								var params = { Bucket: AWS_CONFIG["bucket"], Key: theObjectLocation };
								s3.getSignedUrl('getObject', params, function (err, url) {
									if (err) console.log('something went wrong: ' + err);
									else {
										// window.open(url);
										var iframe = document.createElement('iframe');
										iframe.style.display = "none";
										iframe.src = url;
										document.body.appendChild(iframe);
										//iframe.parentNode.removeChild(iframe);
										//this.appLoadingOverlay("#file-downloading", "", "unblock");	
									}
								
								});
							});	
}	



	
updateChangeFileHistory(addRecID: any) {
	this.caseService.updateCaseFileIdinCahngeFileHistory(addRecID).subscribe(
		data => {
			let returnData = data;
			console.log("dataContact", returnData);									
		},
		error => {
			console.log("error", error);
		});	
	}
	

	appLoadingOverlay(psElem, psMessage, psType) {
		if (psType == 'block') {
			mApp.block(psElem, {
				overlayColor: '#000000',
				type: 'loader',
				state: 'success',
				message: psMessage
			});
		} else {
			mApp.unblock(psElem);
		}
	
	}
	
}
