// tslint:disable-next-line:no-shadowed-variable
import { ConfigModel } from '../core/interfaces/config';

export class AppConstantConfig {

	//UAT
	 //public static API_ENDPOINT = 'http://aalamfolklore.com/aalamproxy/';
	// public static API_ENDPOINT_AUTH = 'http://aalamfolklore.com/aalamproxy/auth/';


	public static API_ENDPOINT_OLD_PORTAL = 'http://192.168.2.17/portal/trivent';
	//public static API_ENDPOINT_OLD_PORTAL = 'http://triuat.triventlegal.net';


	//Local
	 public static API_ENDPOINT = 'http://localhost:8080/trivent/';
	 public static API_ENDPOINT_AUTH = 'http://localhost:8080/trivent/auth/';

//QA
	 //public static API_ENDPOINT = 'http://192.168.2.27:8080/aalamproxy/';
	 //public static API_ENDPOINT_AUTH = 'http://192.168.2.27:8080/aalamproxy/auth/';


//Live
//	public static API_ENDPOINT_AUTH = 'http://appserver-live-3tier.iajrkaw4g9.ap-south-1.elasticbeanstalk.com/auth/';
//	public static API_ENDPOINT = 'http://appserver-live-3tier.iajrkaw4g9.ap-south-1.elasticbeanstalk.com/';
//QA

	//public static OPEN_NEW_TAB_CASE = 'http://aalamfolklore.com/#/case-management/case-detail/';
	//public static OPEN_NEW_TAB_QUERY = 'http://aalamfolklore.com/#/case-management/support-detail/';	
// normal
	public static OPEN_NEW_TAB_CASE = '/#/case-management/case-detail/';
	public static OPEN_NEW_TAB_QUERY = '/#/case-management/support-detail/';		
	
}